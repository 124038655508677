@import 'styles/utils/mixins';
@import 'styles/core/buttons';
@import 'styles/core/typography';
.container {
  width: 100%;
  height: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  @include grid();
  box-shadow: 0 0.4rem 3rem 0 rgba(0, 0, 0, 0.25);
  text-decoration: none;
}
//Todo migliorare con struttura a classe
.container[data-banner-type='HERO'],
.container[data-banner-type='LANDSCAPE'] {
  border-radius: 0.8rem;
  padding-bottom: 3.2rem;
  //padding-bottom: 4.2rem;
  //max-height: 22.4rem;
  text-decoration: none;
  // height: 22.4rem;
  @media screen and (min-width: $breakpoint-sm) {
    border-radius: 1.6rem;
    min-height: 22.6rem;
    padding-bottom: 0;
  }
}
.container[data-banner-type='LANDSCAPE'] {
  min-height: 22.4rem;
}
.container[data-banner-type='HERO'],
.container[data-banner-type='LANDSCAPE'] {
  @media screen and (min-width: $breakpoint-sm) {
    min-height: 22.4rem;
  }
  @media screen and (min-width: $breakpoint-md) {
    min-height: 30.4rem;
  }
  @media screen and (min-width: $breakpoint-xl) {
    min-height: 43.6rem;
  }
}
.container[data-banner-type='PORTRAIT'] {
  border-radius: 1.6rem;
  padding: 0 0 var(--space-size-7);
  @media screen and (min-width: $breakpoint-sm) {
    min-height: 100%;
    height: 100%;
  }
  @media screen and (min-width: $breakpoint-md) {
    padding: 0 var(--space-size-2) var(--space-size-7);
  }
}

.cta {
  @media screen and (min-width: $breakpoint-sm) {
    margin-top: 1.6rem;
    @include button-secondary-small;
  }
  @media screen and (min-width: $breakpoint-lg) {
    @include button-secondary-medium;
  }
  @media screen and (min-width: $breakpoint-xl) {
    margin-top: 3.2rem;
  }
}
