@import 'styles/utils/mixins';
@import 'styles/core/buttons';
@import 'styles/core/typography';

.container {
  border-radius: var(--space-size-1);
  animation-name: loader;
  background-color: var(--color-surface-02);
  position: relative;
  overflow: hidden;

  &::after {
    @include animationLoading(1s, 0.8s);
  }
}

@keyframes loader {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(100%);
  }
}
