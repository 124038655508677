@import 'styles/core/typography';
.container {
  margin-bottom: var(--space-size-2);
  @media screen and (min-width: $breakpoint-sm) {
    margin-bottom: var(--space-size-4);
  }
}
.bulletBanner {
  background-color: var(--color-on-bg-primary);
  width: 0.8rem !important;
  height: 0.8rem !important;
  transition: width 0.2s;
}

.bulletBanner:global(.swiper-pagination-bullet-active) {
  background-color: var(--color-primary) !important;
  width: 3.2rem !important;
  height: 0.8rem !important;
  border-radius: 2.4rem !important;
  transition: width 0.2s;
}
.heroSwiper:global(.swiper-horizontal .swiper-pagination) {
  position: static !important;
  margin-top: 0.8rem !important;
  @media screen and (min-width: $breakpoint-sm) {
    margin-top: 2.4rem !important;
  }
}

.skeleton {
  width: 100%;
  height: 18.8rem;
  @media screen and (min-width: $breakpoint-sm) {
    height: 22.4rem;
  }
  @media screen and (min-width: $breakpoint-md) {
    height: 30.4rem;
  }
  @media screen and (min-width: $breakpoint-xl) {
    height: 43.6rem;
  }
}
