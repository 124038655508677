@import 'styles/utils/shared';
@import 'styles/utils/mixins';
@import 'styles/core/typography';

.titleContainer {
  @include container;

  &.alignCenter {
    text-align: center;
    margin: var(--space-size-7) 0 var(--space-size-4);
    @media screen and (min-width: $breakpoint-md) {
      margin: var(--space-size-12) 0 var(--space-size-5);
    }
  }
}

.link {
  text-decoration: none;
  cursor: pointer;
  color: var(--color-on-bg-primary);
  display: flex;
  align-items: center;
}

.title {
  @include heading-m-medium;
  display: inline-block;
  &::first-letter {
    text-transform: uppercase;
  }
}

.titleIcon {
  width: var(--space-size-2);
  height: var(--space-size-2);
  margin: 0 0 0 var(--space-size-2);

  @media screen and (min-width: $breakpoint-lg) {
    margin: 0 0 0 var(--space-size-3);
  }
}

.subtitle {
  @include body-s-medium;
  color: var(--color-on-bg-secondary);
  margin: var(--space-size-0-5) 0 0 0;

  &::first-letter {
    text-transform: uppercase;
  }

  @media screen and (min-width: $breakpoint-lg) {
    margin: var(--space-size-1) 0 0 0;
  }
}
