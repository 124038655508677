@import 'styles/utils/shared';
@import 'styles/core/typography';

.direttissimeContainer {
  width: unset;
  min-width: 20.7rem;
  @media screen and (min-width: $breakpoint-md) {
    height: auto;
  }
}

.cardContainer {
  scrollbar-width: none;
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  column-gap: var(--space-size-2);
  padding-top: var(--space-size-2);
  width: 100%;
  height: 100%;
  @media screen and (min-width: $breakpoint-lg) {
    padding-top: var(--space-size-4);
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  @include body-medium;
  color: var(--color-on-bg-primary);
  // padding: 0 var(--space-size-3) 1rem;
  @media screen and (min-width: $breakpoint-md) {
    @include heading-s-medium;
    //   padding: 0 var(--space-size-5) 1rem;
  }
}

.urlTitle {
  @include heading-m-medium;
  &::first-letter {
    text-transform: uppercase;
  }
}

.link {
  text-decoration: none;
  cursor: pointer;
  color: var(--color-on-bg-primary);
  display: flex;
  align-items: center;
}

.subtitle {
  @include body-s-medium;
  color: var(--color-on-bg-secondary);
  margin: var(--space-size-0-5) 0 0 0;

  &::first-letter {
    text-transform: uppercase;
  }

  @media screen and (min-width: $breakpoint-lg) {
    margin: var(--space-size-1) 0 0 0;
  }
}

.buttonsContainer {
  display: flex;
  gap: var(--space-size-2);
  @media screen and (min-width: $breakpoint-sm) {
    padding-right: var(--pad-container-sm);
  }

  @media screen and (min-width: $breakpoint-lg) {
    padding-right: var(--pad-container-lg);
  }
}

.buttonPrev {
  display: none;

  @media screen and (min-width: $breakpoint-md) {
    display: flex;
    width: var(--space-size-3);
    height: var(--space-size-3);
    border-radius: 50%;
    border: 0.2rem solid var(--color-primary);
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &::after {
    content: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.6 6L6.6 -6.11959e-08L8 1.4L3.4 6L8 10.6L6.6 12L0.6 6Z' fill='%23E96105'/%3E%3C/svg%3E%0A");
    font-size: unset;
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed;
  }
}

.buttonNext {
  display: none;

  @media screen and (min-width: $breakpoint-md) {
    display: flex;
    width: var(--space-size-3);
    height: var(--space-size-3);
    border-radius: 50%;
    border: 0.2rem solid var(--color-primary);
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &::after {
    content: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.4 6L1.4 12L-6.1196e-08 10.6L4.6 6L-4.63341e-07 1.4L1.4 -6.11959e-08L7.4 6Z' fill='%23E96105'/%3E%3C/svg%3E%0A");
    font-size: unset;
  }
  &:disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed;
  }
}

.titleIcon {
  width: var(--space-size-2);
  height: var(--space-size-2);
  margin: 0 0 0 var(--space-size-2);

  @media screen and (min-width: $breakpoint-lg) {
    margin: 0 0 0 var(--space-size-3);
  }
}
