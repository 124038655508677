@import 'styles/utils/shared';

.container {
  padding-top: var(--space-size-2);
  position: relative;

  @media screen and (min-width: $breakpoint-lg) {
    padding-top: var(--space-size-4);
  }
}

.container :global(.swiper-slide) {
  width: 15.2rem;

  @media screen and (min-width: $breakpoint-lg) {
    width: 19.5rem;
  }
}

.container :global(.swiper) {
  overflow-y: visible;
  overflow-x: clip;
}

.navigationContainer {
  display: none;

  @media screen and (min-width: $breakpoint-lg) {
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
    right: var(--space-size-5);
    top: -6.4rem;
  }
}

.container :global(.swiper > .swiper-button-prev) {
  display: none;

  @media screen and (min-width: $breakpoint-md) {
    display: flex;
    width: var(--space-size-3);
    height: var(--space-size-3);
    border-radius: 50%;
    border: 0.2rem solid var(--color-primary);
    position: absolute;
    top: -3rem;
    right: 7.5rem;
    left: auto;
    z-index: 2;
  }

  @media screen and (min-width: $breakpoint-lg) {
    top: -5.5rem;
  }

  &::after {
    content: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.6 6L6.6 -6.11959e-08L8 1.4L3.4 6L8 10.6L6.6 12L0.6 6Z' fill='%23E96105'/%3E%3C/svg%3E%0A");
    font-size: unset;
  }
}

.container :global(.swiper > .swiper-button-next) {
  display: none;

  @media screen and (min-width: $breakpoint-md) {
    display: flex;
    width: var(--space-size-3);
    height: var(--space-size-3);
    border-radius: 50%;
    border: 0.2rem solid var(--color-primary);
    position: absolute;
    top: -3rem;
    right: 3.6rem;
    left: auto;
  }

  @media screen and (min-width: $breakpoint-lg) {
    top: -5.5rem;
  }

  &::after {
    content: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.4 6L1.4 12L-6.1196e-08 10.6L4.6 6L-4.63341e-07 1.4L1.4 -6.11959e-08L7.4 6Z' fill='%23E96105'/%3E%3C/svg%3E%0A");
    font-size: unset;
  }
}
