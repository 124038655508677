@import 'styles/utils/mixins';
@import 'styles/core/typography';
.label {
  text-align: center;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  min-width: 6.4rem;
  max-height: 2.4rem;
  padding: 0.4rem 1.2rem 0.4rem;
  color: var(--color-neutral-01);
  background-color: var(--color-neutral-02);
  text-transform: uppercase;
  border-bottom-right-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
  border-left: 0.1rem solid;
  border-right: 0.1rem solid;
  border-bottom: 0.1rem solid;
  border-color: var(--color-neutral-01);
  grid-column: 3 / span 2;
  grid-row: 1 / span 1;
  @include label-s-bold;
}

.wrapper {
  grid-column: 1 / span 6;
  grid-row: 2 / span 1;
  text-align: center;
  margin: 0.8rem 3.2rem 0rem;
  padding: 0 var(--space-size-4);

  @media screen and (min-width: $breakpoint-md) {
    padding: 0;
  }
}
.imageContainer {
  grid-column: 1 / span 6;
  grid-row: 1 / span 1;
  width: 100%;
  height: 100%;
  position: relative;
  .image {
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
    object-fit: contain;
    width: auto;
    @media screen and (min-width: $breakpoint-sm) {
      border-top-left-radius: 0rem;
      border-top-right-radius: 0.8rem;
      border-bottom-right-radius: 0.8rem;
    }
  }
}
.title,
.subtitle {
  color: var(--color-neutral-01);
}

.title {
  @include truncate-text(2);
  @include heading-xl-bold;
}

.subtitle {
  margin-top: 0.4rem;
  @include truncate-text(2);
  @include body-xs-medium;
}
//Todo migliorare con struttura a classe
[data-banner-type='HERO'],
[data-banner-type='LANDSCAPE'] {
  .wrapper {
    @media screen and (min-width: $breakpoint-sm) {
      margin: 0rem 0rem 0rem 3.2rem;

      grid-column: 1 / span 3;
    }
    @media screen and (min-width: $breakpoint-md) {
      margin: 0rem;
      grid-column: 2 / span 5;
    }
    @media screen and (min-width: $breakpoint-xl) {
      grid-column: 2 / span 4;
    }
  }
  .label {
    @media screen and (min-width: $breakpoint-sm) {
      left: 0rem;
      grid-column: 1 / span 1;
      margin: 0rem 0rem 0rem 3.2rem;
    }
    @media screen and (min-width: $breakpoint-md) {
      grid-column: 2 / span 1;
      margin: 0rem 0rem 0rem 0rem;
    }
  }
  .subtitle {
    @media screen and (min-width: $breakpoint-md) {
      @include body-s-medium;
    }
  }
  .imageContainer {
    @media screen and (min-width: $breakpoint-sm) {
      grid-column: 4 / span 3;
      grid-row: 1 / span 2;
      .image {
        border-top-left-radius: 0rem;
        border-top-right-radius: 1.6rem;
        border-bottom-right-radius: 1.6rem;
      }
    }
    @media screen and (min-width: $breakpoint-md) {
      grid-column: 7 / span 6;
    }
  }
}

[data-banner-type='HERO'],
[data-banner-type='LANDSCAPE'] {
  .wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: $breakpoint-sm) {
      grid-row: 1 / span 2;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }
  .label {
    @media screen and (min-width: $breakpoint-sm) {
      transform: translate(0);
    }
  }
  .imageContainer {
    min-height: 9.6rem;
  }
}

[data-banner-type='PORTRAIT'] {
  .label {
    @media screen and (min-width: $breakpoint-md) {
      grid-column: 6 / span 2;
    }
  }
  .wrapper {
    margin: var(--space-size-2) 0 0 0;
    grid-column: 1 / span 6;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    @media screen and (min-width: $breakpoint-md) {
      grid-column: 1 / span 12;
    }
  }

  .subtitle {
    margin-top: var(--space-size-1);
  }
  .imageContainer {
    min-height: 18.2rem;
    @media screen and (min-width: $breakpoint-md) {
      grid-column: 1 / span 12;
    }
  }
}

.mobile {
  display: inherit;
  @media screen and (min-width: $breakpoint-md) {
    display: none;
  }
}
.desktop {
  display: none;
  @media screen and (min-width: $breakpoint-md) {
    display: inherit;
  }
}
