@import 'styles/utils/shared';
@import 'styles/utils/mixins';
@import 'styles/core/typography';

.title {
  @include heading-m-medium;
  display: inline-block;
  &::first-letter {
    text-transform: uppercase;
  }
  padding: 0 2rem;
  @media screen and (min-width: $breakpoint-lg) {
    padding: 0 var(--space-size-5);
  }
}

.paddingLeft {
  padding: 0 2rem;

  @media screen and (min-width: $breakpoint-lg) {
    padding: 0 var(--space-size-5);
  }
}

.navigationContainer {
  display: none;

  @media screen and (min-width: $breakpoint-lg) {
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
    right: var(--space-size-5);
    top: -6.4rem;
  }
}

.cardContainerSlot {
  column-gap: var(--space-size-3) !important;
  padding: var(--space-size-2) 2rem;

  @media screen and (min-width: $breakpoint-lg) {
    padding: var(--space-size-4) var(--space-size-5);
  }
  & > article {
    flex-grow: 0;
    width: 25.1rem;
    height: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    width: 15.2rem;

    @media screen and (min-width: $breakpoint-lg) {
      width: 19.5rem;
    }

    &.app {
      width: 12rem;
    }
  }
}
