@import 'styles/utils/_shared';
@import 'styles/utils/mixins';

.container {
  gap: 16px;
  padding: 32px;
  display: flex;
  margin-top: var(--space-size-4);
  background: var(--color-surface-01);
  align-items: flex-start;
  flex-direction: column;

  @media screen and (min-width: $breakpoint-md) {
    margin: var(--space-size-4);
    margin-left: 0;
  }

  @media screen and (min-width: $breakpoint-lg) {
    margin: var(--space-size-4) var(--space-size-5);
    margin-left: 0;
  }

  @media screen and (min-width: $breakpoint-xl) {
    margin: var(--space-size-5); // 0 var(--space-size-5) var(--space-size-5);
    margin-left: 0;
  }
}

.main {
  &-title {
    & svg,
    & img {
      display: none;
    }

    cursor: default;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.48px;
  }

  &-wrapper {
    margin-top: 16px;
    margin-bottom: 24px;
  }

  &-content {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.96px;

    color: var(--color-foreground-seconday);
  }
  width: 100%;
}

.accordion {
  &-title {
    & svg,
    & img {
      width: 10px;
      height: 10px;
    }

    & *[data-kind='title'] {
      flex-grow: 1;
      text-align: left;
    }

    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;
    padding: 16px 16px 16px 24px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    border-radius: 4px;
    letter-spacing: 0.96px;

    background: var(--color-surface-02);
  }

  &-wrapper {
    gap: 16px;
    display: flex;
    align-self: stretch;
    align-items: flex-start;
    border-radius: 4px;
    flex-direction: column;

    background: var(--color-surface-03);
  }

  &-collapsed {
    display: none;
  }
  &-expanded {
    display: unset;
  }

  &-content {
    flex: 1 0 0;
    padding: 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.96px;
  }

  width: 100%;
}
